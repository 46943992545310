.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html, body, #root{
  height: 100%!important;
}

.animated{
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.flexContainer{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
}

.maxHeight{
        min-height: calc(100vh - 64px - 4em);

}

.flexItem{
    text-align: center;
    flex-grow:1;
}

.centered{
    align-self: center;
}

.vertCenter{
  justify-content: center;
  align-items: center;
}

.MuiContainer-root{
    padding-top:1em;
    padding-bottom: 1em;
}
div.MuiPaper-root{
    padding: 1em;
}
.MuiPaper-root{
    border: 1px solid #3c4043;
}